<template>
    <div>
        <el-container>
            <el-header height="50px">
                <el-row>
                    <vxe-form size="mini">
                        <vxe-form-item :span="3" title="单据号" field="name" :item-render="{}">
                            <vxe-input v-model="p.code" size="mini" placeholder=""></vxe-input>
                        </vxe-form-item>
                        <vxe-form-item :span="2" field="name" :item-render="{}">
                            <vxe-button size="mini" content="查询" @click="select1"></vxe-button>
                        </vxe-form-item>
                        
                    </vxe-form>
                </el-row>
            </el-header>
            <el-container>
                <div style="width:700px">
                    <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow
                    highlight-hover-row size="mini" :data="rs" height="600"
                    @current-change="rowclick"
                    >
                        <vxe-table-column field="account" title="账套" width="100"></vxe-table-column>
                        <vxe-table-column field="voucherdate1" title="单据日期" width="100"></vxe-table-column>
                        <vxe-table-column field="code" title="单据号" width="150"></vxe-table-column>
                        <vxe-table-column field="partnername" title="供应商" width="100"></vxe-table-column>
                        <vxe-table-column field="id" title="操作" width="80">
                            <template #default="{row, rowIndex }">
                                <vxe-button size="mini" content="下单" @click="apipu(row)"></vxe-button>
                            </template>
                        </vxe-table-column>
                    </vxe-table>
                </div>
                <div style="width: 820px">
                    <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow
                    highlight-hover-row size="mini" :data="rs1" height="600" @checkbox-change="selectb" @checkbox-all="selectb">
                        <vxe-table-column field="inventorycode" title="存货编码" width="100"></vxe-table-column>
                        <vxe-table-column field="inventoryname" title="存货名称" width="150"></vxe-table-column>
                        <vxe-table-column field="specification" title="规格" width="150"></vxe-table-column>
                        <vxe-table-column field="compositionQuantity" title="数量" width="100"></vxe-table-column>
                        <vxe-table-column field="origTaxPrice" title="单价" width="100"></vxe-table-column>
                        
                    </vxe-table>
                </div>
                <el-main>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    export default {
        name: "puorderexec",
        extends: ax.control(),
        data() {
            return {
                paramssyc:{details:[],
                classes:[],
            },
                p:{
                    code:"",
                },
                rs:[],
                rs1:[]
                
            }
        },
        mounted() {
            //this.dataset.type = "inventoryclass";
            //this.getaccounts();
        },
        methods: {
            apipu(row){
                let _this = this;
                ax.base.post("/syc1/apipu", row).then(data => {
                    if(data.data.rs == 'null'){
                        _this.$XModal.message({ message: "下单成功!", status: 'success' })
                        _this.select1();
                    }else{
                        _this.$XModal.message({ message: data.data.rs, status: 'error' })
                    }
                    
                }).catch(data => {
                    _this.$XModal.message({ message: data.data, status: 'error' })
                });
            },
            select1() {
                let _this = this
                
                ax.base.post("/syc1/selectPuorder", _this.p).then(data => {
                    console.log(data.data)
                    _this.rs = data.data;
                    
                }).catch(data => {
                });
            },
            rowclick({ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, $event }) {
                console.log(row);
                let _this = this
                
                ax.base.post("/syc1/selectPuorderb", {"id":row.id,"db":row.db}).then(data => {
                 
                    _this.rs1 = data.data;
                    
                }).catch(data => {
                });
            },
            pagechange({ currentPage, pageSize }) {
                this.params.page.current = currentPage;
                this.params.page.size = pageSize;
                this.select();
            },
            syc() {
                let _this = this;
                console.log(_this.paramssyc)
                //return;
                ax.base.post("/syc1/sycinventoryclass",_this.paramssyc).then(data=>{
                    _this.$XModal.message({ message: data.data, status: 'success' })
                })
                .catch(data=>{
                    _this.$XModal.message({ message: data.data, status: 'error' })
                })
            },
            selecta({ records }) {
                this.paramssyc.details = records;
            },
            selectb({ records }) {
                this.paramssyc.classes = records;
            },
        }
    }
</script>
<style scoped>
    .el-header,
    .el-footer {
        /* background-color: #b3c0d1; */
        color: #333;
        /* text-align: center; */
        /* line-height: 20px; */
    }

    .el-main {
        background-color: #e9eef3;
        color: #333;
        /* text-align: center; */
        line-height: 400px;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }
</style>